<template>
<div>
<div class="hero-section">
  <div class="hero-section-text">
    <h1><img src="/images/large_logo.png" alt="BOATNAVI報知" /></h1>
  </div>

</div>
 <div class="site-catch">
<img src="/images/catch.svg" alt="全場全レースデータ掲載 AI予想×データ解析で舟券攻略" />
</div>

<div class="site-description">
  <p>スポーツ報知が運営するボートレース専門サイト「ボートナビ報知」。全場、全レースで予想に必須なデータのほか、ＡＩ予想やスポーツ報知が開発した分析データ報知スコープがご覧頂けます。</p>
</div>



<div v-if="information_list != null && Array.isArray(information_list) && information_list.length > 0" class="information-list-wrapper">
  <h2>お知らせ</h2>
  <ul class="information-list">
    <li v-for="item in information_list" :key="item.id">
      <router-link :to="{name:'Information',params:{id:item.id}}" :class="item.important?'important':''">
        {{item.title}}
        <time>({{ item.datetime }})</time>
      </router-link>
    </li>
  </ul>
</div>

<div class="parts-container">
<div class="day-jo-list-header">
<span class="prev-container">
<a  class='prev-dayrace' @click="changeRaceDate(prev_date_str)">{{ prev_date_str.substr(0,4)+'年' + prev_date_str.substr(4,2)+'月'+ prev_date_str.substr(6,2)+'日' }}</a>
</span>
<h2 class="parts-title day-jo-list-title">{{ date_str.substr(0,4)+'年' + date_str.substr(4,2)+'月'+ date_str.substr(6,2)}}日のレース</h2>
<span class="next-container">
<a  class='next-dayrace' v-show="nextday_ready" @click="changeRaceDate(next_date_str)">{{ next_date_str.substr(0,4)+'年' + next_date_str.substr(4,2)+'月'+ next_date_str.substr(6,2)+'日' }}</a>
</span>
</div>
<ul class="day-jo-list grid-x">
       <li v-for="(active,index) of jo_active" :key="index"  class="cell small-4 medium-2">
         <span v-if="active">
         <router-link class="button" :to="{ name: 'JoDateIndex', params: {jo:getProp(JO_ID_NAMES[index],'id'),date:date_str}}"><span v-if="(index == 19 && date_str == '20210811') ||
          (index == 7 && date_str == '20210823') ||  (index == 20 && date_str == '20210830') || (index == 13 && date_str == '20210918') ||
           (index == 2 && date_str == '20211001') || (index == 0 && date_str == '20211024')  || 
          /* (index == 15 && date_str == '20220918') || */ (index == 19 && date_str == '20220918') || (index == 21 && date_str == '20220918') ||  (index == 22 && date_str == '20220918') ||
          (index == 6 && date_str == '20220919') || (index == 8 && date_str == '20220919') || (index == 11 && date_str == '20220919') ||  (index == 12 && date_str == '20220919') ||
          (index == 13 && date_str == '20220919') || (index == 15 && date_str == '20220919') || (index == 19 && date_str == '20220919') ||  (index == 21 && date_str == '20220919') ||
          (index == 22 && date_str == '20220919') " class="cancel">中止</span>{{ getProp(JO_ID_NAMES[index],'name')}}

         <span v-if="isGrade(index)" class="grade-label" :class="'g-'+jo_headers[index].gcd">{{ jo_headers[index].grade }}</span>
         <span v-if="jo_headers[index].nflg > 0" class="n-icon" :class="'type-'+jo_headers[index].nflg"></span>
         <span v-if="isLady(index)" class="f-hearts-icon" :class="'lgrade-'+jo_headers[index].gcd"></span>
         </router-link>
         </span>
         <span v-else><span class="button disabled">{{ getProp(JO_ID_NAMES[index],'name')}}</span></span>
       </li>
</ul>
<div class="icon-info">
  <span class="icon-info-item"><span class="emoji-icon"><img src="/images/icon_nighter.png" alt="" class="icon-pict" /></span>ナイター</span>
  <span class="icon-info-item"><span class="emoji-icon"><img src="/images/icon_midnight.png" alt="" class="icon-pict" /></span>ミッドナイト</span>
  <span class="icon-info-item"><span class="emoji-icon"><img src="/images/icon_morning.png" alt=""  class="icon-pict"  /></span>モーニング</span>
  <span class="icon-info-item"><span class="emoji-icon"><img src="/images/icon_summertime.png" alt=""  class="icon-pict" /></span>サマータイム</span>
  <span class="icon-info-item"><span class="c-icon lgrade-6"><img src="/images/icon_alllady.png" alt="" class="icon-pict" /></span>オールレディース</span>
  <span class="icon-info-item"><span class="c-icon lgrade-8"><img src="/images/icon_venus.png" alt="" class="icon-pict" /></span>ヴィーナスシリーズ</span>
</div>

</div>

<router-link v-if="date_str == today_str" class="button dayrace-button" :to="{name:'DayRaceList',params:{date:today_str}}">本日のレース</router-link>
<router-link v-else class="button dayrace-button" :to="{name:'DayRaceList',params:{date:date_str}}">{{ date_str.substr(0,4)+'年'+date_str.substr(4,2)+'月'+ date_str.substr(6,2) +'日'}}のレース</router-link>

<div v-if="article_list != null && Array.isArray(article_list) && article_list.length > 0" class="news-wrapper">
  <h2>ニュース</h2>
  <ul class="article-list">
    <li v-for="item in article_list" :key="item.id">
      <router-link :to="{name:'Article',params:{id:item.id}}">
        {{item.title}}
        <time>({{ item.datetime }})</time>
      </router-link>
    </li>
  </ul>
</div>

</div>
</template>
<script>
import JO_ID_NAMES from '../mixins/PrefsMixin'
import API_BASE_URL from '../mixins/PrefsMixin'
import pianoSend from '../mixins/PrefsMixin'


export default {
  name: 'Index',
  mixins: [ JO_ID_NAMES,API_BASE_URL,pianoSend],
  data:function(){
    return {
      date:{},
      today:{},
      jo_active:[],
      jo_headers:[],
      article_list:[],
      information_list:[],
      today_str:'',
      date_str:'',
      prev_date_obj:{},
      next_date_obj:{},
      source:{},
      prevday_ready:true,
      nextday_ready:false
    }
  },
  beforeRouteUpdate(_to, _from, _next) {
    _next();
  },
  created:function(){
    this.$data.today = new Date();
    this.$data.date = new Date();
    this.$data.today_str = '' + this.$data.today.getFullYear() + ('0'+(this.$data.today.getMonth()+1)).slice(-2) + ('0'+this.$data.today.getDate()).slice(-2);
    this.$data.date_str = this.$data.today_str;
    this.$data.jo_active = new Array(this.JO_ID_NAMES.length);
    this.$data.prev_date_obj = new Date();
    this.$data.prev_date_obj.setDate(this.$data.today.getDate()-1);
    this.$data.next_date_obj = new Date();
    this.$data.next_date_obj.setDate(this.$data.today.getDate()+1);
    this.pianoSend('トップページ');

    this.$data.source = this.axios.CancelToken.source();
    this.getData();
   
  },
  computed:{
    prev_date_str(){
      return this.printDateStr(this.$data.prev_date_obj);
    },
    next_date_str(){
      return this.printDateStr(this.$data.next_date_obj);
    }
  },
  methods: {
    changeRaceDate(date_str){
      this.$data.source.cancel();
      this.$data.source = this.axios.CancelToken.source();

      this.$data.jo_active = new Array(this.JO_ID_NAMES.length);
      this.$data.jo_headers =[];
      this.$data.date_str = date_str;
      this.$data.date = new Date(this.$data.date_str.substr(0,4),Number((this.$data.date_str.substr(4,2)))-1,this.$data.date_str.substr(6,2));
      this.$data.prev_date_obj = new Date(this.$data.date_str.substr(0,4),Number((this.$data.date_str.substr(4,2)))-1,this.$data.date_str.substr(6,2)); 
      this.$data.prev_date_obj.setDate(this.$data.prev_date_obj.getDate()-1);
      this.$data.next_date_obj = new Date(this.$data.date_str.substr(0,4),Number((this.$data.date_str.substr(4,2)))-1,this.$data.date_str.substr(6,2));
      this.$data.next_date_obj.setDate(this.$data.next_date_obj.getDate()+1);
   
      
      this.getData();
    },
    printDateStr(dateObj){
      
      let datestr = dateObj.getFullYear();
      datestr += ('00' + Number(dateObj.getMonth()+1)).slice(-2);
      datestr += ('00' + dateObj.getDate()).slice(-2);
      
      return datestr
    },
    getProp(object,propertyPath){
      if (!object) { return undefined }
      let result = object;
      const propertyArray = propertyPath.split('.');
      for (let i = 0; i <= propertyArray.length - 1; i += 1) {
        if (propertyArray[i] === '' ) { return undefined; }
        if (typeof result[propertyArray[i]] === 'undefined') { return undefined; }
        result = result[propertyArray[i]];
      }
      return result;
    },
    isGrade(index){
    
      if(this.$data.jo_headers[index]){
      let gcd = this.$data.jo_headers[index]['gcd'];
        if(gcd == 0 || gcd == 1 || gcd == 2 || gcd == 3 || gcd == 5 ){
          return true;
        }
      }

      if(this.$data.jo_headers[index]){
        let grade_label = this.$data.jo_headers[index]['grade'];
        if(grade_label == 'SＧ' || grade_label == 'ＧⅠ' || grade_label == 'ＧⅡ' || grade_label == 'ＧⅢ' ){
          return true;
        }
      }
      return false;
    },
    isLady(index){
     
     if(this.$data.jo_headers[index]){
     let gcd = this.$data.jo_headers[index]['gcd'];
     let lgrade = this.$data.jo_headers[index]['lgrade'];
      
      if(gcd == 5 || gcd == 6 || gcd == 8 || lgrade == 'JO'){
        return true;
      }
     }
      return false;
    },
    joLinkOn(j_index){
      this.$set(this.$data.jo_active,j_index,true)
    },
    getData() { 
        let date_racelist_url =  this.API_BASE_URL + 'kaisailist/' + this.$data.date_str ;
        
      
        
          this.axios.get(date_racelist_url,{cancelToken: this.$data.source.token})
          .then((response) => {
       
            if(response.data.headers){
              response.data.headers.forEach(element => {
                const j_index = Number(element.jcd) - 1;
                this.$data.jo_headers[ j_index ] = element;
                this.joLinkOn( j_index );
              });
            }
            this.$data.nextday_ready = response.data.nextday_ready;

          })
          .catch((e) => { // eslint-disable-line
        
          });
      

          this.axios.get('/article_contents/index.json')
          .then((response) => {
          
            this.$data.article_list = response.data;
          })
          .catch((e) => { // eslint-disable-line
            
          });

          this.axios.get('/information_contents/index.json')
          .then((response) => {
            
            this.$data.information_list = response.data;
          })
          .catch((e) => { // eslint-disable-line
          
          });


      }
  }
}
</script>

<style lang="scss" scoped>
.dayrace-button{
  display:block;
  max-width:300px;
  margin:0 auto 20px auto;
  border-radius: 20px;
}
.site-description{
  padding:20px;
  background-image: linear-gradient(180deg, #F0F8FF 0%, #FFFFFF 100%);
}
.grade-label{
   padding:2px !important;
   margin:0 5px;
   display:inline-block;
   min-width:auto;
   text-align: center;
   position:absolute;
   left:0;
   top:50%;
   transform: translateY(-50%);
   color:#FFFFFF;
   font-size:11px;
}

.f-hearts-icon{
  position:absolute;
  font-family: sans-serif;
  top:50%;
  right:5px;
  font-size:12px;

  //text-indent: -9999px;
  overflow: hidden;
  transform: translateY(-50%);
}



.f-hearts-icon.lgrade-6{
   color:#ec1479;

}
.f-hearts-icon.lgrade-8{
   color:#fccee3;
   
}

.n-icon{
  font-family:apple color emoji,segoe ui emoji,noto color emoji,android emoji,emojisymbols,emojione mozilla,twemoji mozilla,segoe ui symbol;
  display: inline-block;
  position:absolute;
  top:50%;
  right:5px;
  transform: translateY(-50%);
}
.n-icon.type-1::before{
  content:'ナイター';
  width:12px;
  height:12px;
  text-indent: -9999px;
  background: url(/images/icon_nighter.png) center center no-repeat;
  background-size: contain;
  display:inline-block;
}
.n-icon.type-2::before{
  content:'サマータイム';
  width:12px;
  height:12px;
  text-indent: -9999px;
  background: url(/images/icon_summertime.png) center center no-repeat;
  background-size: contain;
  display:inline-block;
}
.n-icon.type-3::before{
  content:'モーニング';
  width:12px;
  height:12px;
  text-indent: -9999px;
  background: url(/images/icon_morning.png) center center no-repeat;
  background-size: contain;
  display:inline-block;
}
.n-icon.type-4::before{
  content:'ミッドナイト';
  width:12px;
  height:12px;
  text-indent: -9999px;
  background: url(/images/icon_midnight.png) center center no-repeat;
  background-size: contain;
  display:inline-block;
}

.n-icon + .f-hearts-icon  {
    right:18px;
}
.emoji-icon{
  font-family:apple color emoji,segoe ui emoji,noto color emoji,android emoji,emojisymbols,emojione mozilla,twemoji mozilla,segoe ui symbol;
  display: inline-block;
}

.icon-info{
  background-color:#F0F8FF;
  padding:10px;
  font-size:13px;
  text-align: center;
  line-height: 1.7;
}

.icon-info-item{
  display:inline-block;
  margin-right:10px;
}

.banner{
  max-width:960px;
  margin:10px auto 20px auto;
}

.banner a:hover img,
.banner a:focus img{
  opacity: 0.7;
}

.banner a:active{
  opacity: 0.9;
}


.a-info{
  color:#fc320f;
  background-color:rgb(255,227,219);
  padding:5px 20px;
  max-width:960px;
  margin:-19px auto 10px auto;
}

.site-catch{
  display: block;
  text-align: center;
  background-color:#F0F8FF;
}

.site-catch img{
  margin-top:-50px;
}


.news-wrapper{
  padding:10px;


}

.news-wrapper h2{
    margin:10px;
    background-image:url(/images/icon_newspaper.svg);
    background-position: 0 50%;
    background-size:contain;
    background-repeat: no-repeat;
    padding-left:40px;
    color:#032C50;
}

.special-w-banner{
  display:grid;
  grid-template-columns:1fr 1fr;
  gap:10px;
  margin:40px auto;

  .special-banner{
    margin:0;
  }
}

@media screen and (max-width:428px) {
  .news-wrapper h2{
    padding-left:30px;
  }
  .special-w-banner{
    grid-template-columns: 100%;
    margin:0 10px 20px 10px;
  }
}

/* .news-wrapper h2::before{
  content:'\1F4F0';
  display: inline-block;
  margin-right:5px;
}  */


.news-wrapper .article-list{
  border-top:1px solid #080024;
  /*
  border-bottom:1px solid #CDCDCD;
  */
  list-style:none;
  margin-left:0;
  padding:20px 10px 20px 10px;
}

.news-wrapper .article-list li{
  margin-bottom:15px;
  border-bottom:1px dotted #c7c7c7;
  padding-bottom:15px;
}
/*
.news-wrapper .article-list li:last-child{
  border-bottom:none;
   padding-bottom:0px;
   margin-bottom:0px;
}
*/



.news-wrapper .article-list li{
  margin-bottom:15px;
  border-bottom:1px dotted #c7c7c7;
  padding-bottom:15px;
}

.news-wrapper .article-list li a{
  display:inline-block;
  font-size:18px;
  padding-left:20px;
  position: relative;
}


.information-list-wrapper{
  border:1px solid #daebff;
  list-style:none;
  margin-left:0;
  padding:20px 10px 10px 10px;
  border-radius: 2px;
  margin:0px 10px 30px 10px;
}
.information-list-wrapper h2{
  font-size:16px;
  margin-left:20px;
  background-image:url(/images/icon_information.svg);
  background-position: 0 50%;
  background-size:contain;
  background-repeat: no-repeat;
  padding-left:25px;
  color:#032C50;
}

.information-list-wrapper .information-list{
  border-top:1px solid #daebff;
  /*
  border-bottom:1px solid #CDCDCD;
  */
  list-style:none;
  margin:10px 20px 0 20px;
  padding:20px 10px 10px 0;
}

.information-list-wrapper .information-list li{
  margin-bottom:20px;
}

.information-list-wrapper .information-list li:last-child{
  margin-bottom:0;
}

.information-list-wrapper .information-list li a.important{
  color:#fc320f;
  font-weight: bold;
}
.information-list-wrapper .information-list li a.important:hover,
.information-list-wrapper .information-list li a.important:focus{
  color:#ff826c;
}
.information-list-wrapper .information-list li a.important:active{
  color:#f53b1a;
  font-weight: bold;
}

.information-list-wrapper .information-list li a{
  display:inline-block;
  padding-left:20px;
  position: relative;
  font-size:16px;
}

.information-list-wrapper .information-list li  a::before{
  display:inline-block;
  position: absolute;
  content:'▶︎';
  left:0;
}
.information-list-wrapper .information-list li a time{
  display:inline-block;
  color:#666;
  font-weight: normal;
  font-size:14px;
}

@media screen and (max-width:375px) {
  .information-list-wrapper h2{
    margin-left:10px;
  }
  
  .information-list-wrapper .information-list{  
    margin:10px 10px 0;
    padding:20px 0 10px 0;
  }

  .information-list-wrapper .information-list li a{
    font-size:14px;
  }
  .information-list-wrapper .information-list li a time{
    font-size:12px;
  }

}


@media screen and (max-width:375px) {
  .news-wrapper .article-list li a{
    font-size:14px;
  }
}
.news-wrapper .article-list li a::before{
  display:inline-block;
  position: absolute;
  content:'▶︎';
  left:0;
}

.news-wrapper .article-list li a time{
  display:block;
  color:#666;
  font-size:14px;
  font-weight: normal;
}

@media screen and (max-width:860px) {
  .site-catch img{
  margin-top:-10px;
} 
}
@media screen and (max-width:428px) { 
  .banner{
    max-width:none;
    margin:-5px auto 20px auto;
    width:calc(100%  - 20px);
  }

  .a-info{
    max-width:none;
    margin:-19px auto 10px auto;
    width:calc(100%  - 20px);
}
  .site-catch img{
    margin-top:-15px;
  } 

}
@media screen and (max-width:375px) { 

  .n-icon + .f-hearts-icon  {
    right:12px;
  }
   .n-icon{

      font-size:10px;
   }


}

@media screen and (max-width:340px) { 
  .day-jo-list li > span .button{
    padding-bottom:20px;
  }
   .n-icon{
     top:auto;
     bottom:0;
   }
   .f-hearts-icon  {
     top:auto;
     bottom:0;
    font-size:11px;
   }

   .grade-label{
     font-size:10px;
     top:auto;
     left:-3px;
        transform: translateY(0);
         
   }
     
}



.day-jo-list-header{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media screen and (max-width:800px) { 
   // padding:10px;
    grid-template-columns: repeat(2, 1fr);
    .prev-dayrace{
      vertical-align: baseline;
    }

    .day-jo-list-title{
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 3;
      padding-top:15px;
    }

    .next-dayrace{
      vertical-align: baseline;
    }
  }
}
.day-jo-list-title {
  //display: inline-block;
  text-align: center;
}
.prev-dayrace{
  &::before{
    display:inline-block;
    font-size:13px;
    content:'◀︎';
    background-color:#1579ad;
    color:#FFF;
   width:18px;
   height:18px;
   line-height: 18px;
    text-align: center;
    border-radius: 9px;
    vertical-align: text-top;
    margin-right: 3px;
    
  }
}
.next-container{
    text-align: right;
}
.next-dayrace{
 
   &::after{
   display:inline-block;
   content:'▶';
   font-size:13px;
   background-color:#1579ad;
   color:#FFF;
   width:18px;
   height:18px;
   text-align: center;
   line-height: 18px;
    border-radius: 9px;
    vertical-align: text-top;
    margin-left: 3px; 
  }
}


.cancel{
  display:inline-block;
  font-size:8px;
  color:#FFF;
  background-color:#f53b1a;
  margin-right:5px;
    -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  position: absolute;
  left:0;
  top:0;
  height: 100%;
  padding:0 1px;
}

.cancel + .grade-label{
  margin-left:15px;
  @media screen and (max-width:640px) { 
      margin-left:12px;
      transform:translateY(-50%)  scale(0.75) ;
  
  }
}
.topics-thumb-links-header{
  margin:0 15px;
  padding:5px 10px 5px 23px;
  font-size:16px;
  font-weight:bold;
  color:#1a1a1a;

  background-image: url(/images/topics/icon_play.svg);
  background-position: 0 50%;
  background-size: 20px;
  background-repeat: no-repeat;

  @media screen and (max-width:640px) { 
    font-size:14px;
  }
  border-bottom:1px solid #daebff;
}

.topics-thumb-links{
  display: flex;
  gap: 10px;
  margin:15px;
  &__item{
    flex:auto auto auto;
    
    img{
      display:inline-block;
    }
    &:hover{
      opacity: 0.6;
    }
    &:active{
      opacity: 0.8;
    }
  }
}

.special-banner{
  margin:30px 10px;
  text-align:center;

 img{
     border-radius: 2px;
     margin:0 auto;
 }

 img.desktop{
   max-width:960px;
   width:100%;
 }
  img.mobile{
    display:none;
  }
    @media screen and (max-width:640px) { 
       img.desktop{
         display: none;
      }
      img.mobile{
        display:inline;
        width:100%;
       }

       margin-top:0;
    }

    a{
      box-shadow: 2px 2px 3px rgba(0,0,0,0.4);
      @media screen and (max-width:640px) {
        box-shadow: 1px 1px 3px rgba(0,0,0,0.4);
      }
      display:inline-block;
     
      &:hover,&:focus{
        opacity: 0.6;
      }

      &:active{
        opacity: 0.9;
        box-shadow: 0px 0px 5px rgba(0,0,0,0.4);
      }
    }

}


</style>